<template>
  <div
    id="report"
    class="report"
  >
    <el-row>
      <el-col :span="12">
        测评时间：{{ evalData.evalDate||'-' }}
      </el-col>
      <el-col
        :span="12"
        class="right"
      >
        {{ orgName }}
      </el-col>
    </el-row>
    <h3 class="head center">
      儿童健康成长动态测评报告
    </h3>
    <!-- 基本信息 -->
    <div class="title">
      宝贝基本信息
    </div>
    <div class="panel">
      <el-row>
        <el-col :span="6">
          名字：{{ evalData.name||'-' }}
        </el-col>
        <el-col :span="6">
          性别：{{ evalData.childrenSex|sexConvert }}
        </el-col>
        <el-col :span="6">
          孕周：{{ evalData.gestationalWeeks||'-' }}
        </el-col>
        <el-col :span="6">
          出生年月日：{{ evalData.childrenBirthday||'-' }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          年龄：{{ evalData.evalAge||'-' }}
        </el-col>
        <el-col :span="6">
          身高：{{ evalData.height||'-' }}cm
        </el-col>
        <el-col :span="6">
          体重：{{ evalData.bodyWeight||'-' }}kg
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          疾病史、家族疾病史、过敏史：{{ evalData.medicalHistory||'-' }}
        </el-col>
      </el-row>
    </div>
    <!-- 体格评价 -->
    <template v-if="chartOption.data.haveEvalRecord">
      <div class="title">
        0-2岁体格评价
      </div>
      <div class="panel long">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="center">
              身长测量结果评价
            </div>
            <div class="chart-box">
              <Chart :option="chartOption.option.height||{}" />
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="center">
              体重测量结果评价
            </div>
            <div class="chart-box">
              <Chart :option="chartOption.option.weight||{}" />
            </div>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="20">
          <el-col
            v-if="twoYearsOld"
            :span="12"
          >
            <div class="center">
              体型匀称度测量结果评价
            </div>
            <div class="chart-box">
              <Chart :option="chartOption.option.bodyMassIndex||{}" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="chart-box flex-center text">
              体型匀称度评价：{{ chartOption.data.bodyMassIndexDesc||'-' }} <br>
              (小于2岁时，无百分位图，体型匀称度评价：需申请专家报告评价)
            </div>
          </el-col>
        </el-row> -->
      </div>
    </template>
    <template v-if="chartOption2.data.haveEvalRecord">
      <div class="title">
        大于2岁体格评价
      </div>
      <div class="panel long">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="center">
              身高测量结果评价
            </div>
            <div class="chart-box">
              <Chart :option="chartOption2.option.height||{}" />
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="center">
              体重测量结果评价
            </div>
            <div class="chart-box">
              <Chart :option="chartOption2.option.weight||{}" />
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="center">
              体型匀称度测量结果评价
            </div>
            <div class="chart-box">
              <Chart :option="chartOption2.option.bodyMassIndex||{}" />
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="text">
              体型匀称度评价：{{ chartOption2.data.bodyMassIndexDesc||'-' }} <br>
            </div>
          </el-col>
        </el-row>
      </div>
    </template>
    <!-- 发育商评价 -->
    <div class="title">
      发育商评价
    </div>
    <div class="panel">
      <div class="center">
        发育商综合测评结果
      </div>
      <div class="chart-box">
        <Chart :option="convertEvalData(evalData.evaluationScoreList, {key: 'itemTotalScore',yAxisName: '分值', xAxisName: '月',yAxisMax: 100})" />
      </div>
      <div class="center">
        平衡与大运动动态测评结果
      </div>
      <div class="chart-box">
        <Chart :option="convertEvalData(evalData.evaluationScoreList, {key: 'item2Score',yAxisName: '分值', xAxisName: '月',yAxisMax: 100})" />
      </div>
      <div class="center">
        精细动作动态测评结果
      </div>
      <div class="chart-box">
        <Chart :option="convertEvalData(evalData.evaluationScoreList, {key: 'item3Score',yAxisName: '分值', xAxisName: '月',yAxisMax: 100})" />
      </div>
      <div class="center">
        适应能力动态测评结果
      </div>
      <div class="chart-box">
        <Chart :option="convertEvalData(evalData.evaluationScoreList, {key: 'item4Score',yAxisName: '分值', xAxisName: '月',yAxisMax: 100})" />
      </div>
      <div class="center">
        语言与感知动态测评结果
      </div>
      <div class="chart-box">
        <Chart :option="convertEvalData(evalData.evaluationScoreList, {key: 'item1Score',yAxisName: '分值', xAxisName: '月',yAxisMax: 100})" />
      </div>
      <div class="center">
        社会行为动态测评结果
      </div>
      <div class="chart-box">
        <Chart :option="convertEvalData(evalData.evaluationScoreList, {key: 'item5Score',yAxisName: '分值', xAxisName: '月',yAxisMax: 100})" />
      </div>
    </div>
    <!-- 测评成绩单 -->
    <div class="result">
      <div class="title">
        测评成绩单
      </div>
      <div class="note">
        本次测评年龄段采用{{ evalData.evalMonth||'-' }}月标准
      </div>
    </div>
    <div style="margin-top:12px">
      <el-table
        :data="evalData.evaluationResults||[]"
        border
        style="width: 100%;border-radius:4px;"
      >
        <el-table-column
          prop="itemName"
          align="center"
          label="测评项目"
        />
        <el-table-column
          prop="item1"
          align="center"
          label="语言与感知"
        />
        <el-table-column
          prop="item2"
          align="center"
          label="平衡与运动"
        />
        <el-table-column
          prop="item3"
          align="center"
          label="精细动作"
        />
        <el-table-column
          prop="item4"
          align="center"
          label="适应能力"
        />
        <el-table-column
          prop="item5"
          align="center"
          label="社会行为"
        />
        <el-table-column
          prop="itemTotalScore"
          align="center"
        >
          <div slot="header">
            综合总分 <br>
            5项分值和/5
          </div>
        </el-table-column>
      </el-table>
    </div>

    <!-- 本次测评得分与前次测评得分比较 -->
    <div class="title">
      本次测评得分与前次测评得分比较
    </div>
    <div class="panel">
      <el-row>
        <el-col :span="8">
          成长指标测评总分差别：{{ evalData.resultDifference && evalData.resultDifference.itemDiffTotalScore }}
        </el-col>
        <el-col :span="8">
          语言与感知测评差别：{{ evalData.resultDifference && evalData.resultDifference.item1DiffScore }}
        </el-col>
        <el-col :span="8">
          平衡与大运动测评差别：{{ evalData.resultDifference && evalData.resultDifference.item2DiffScore }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          精细动作测评差别：{{ evalData.resultDifference && evalData.resultDifference.item3DiffScore }}
        </el-col>
        <el-col :span="8">
          适应能力测评差别：{{ evalData.resultDifference && evalData.resultDifference.item4DiffScore }}
        </el-col>
        <el-col :span="8">
          社会行为测评差别：{{ evalData.resultDifference && evalData.resultDifference.item5DiffScore }}
        </el-col>
      </el-row>
    </div>

    <!-- 预警行为提示 -->
    <div class="title">
      预警行为提示
    </div>
    <div class="panel">
      {{ warnStr }}
    </div>

    <!-- 本次成长指标测评总分评价 -->
    <div class="title">
      本次成长指标测评总分评价
    </div>
    <div class="panel text pic-box">
      <div>
        <img
          v-if="evalData.totalEvaluationLevel"
          :src="require(`./images/level/${evalData.totalEvaluationLevel}.svg`)"
        >
      </div>
      <div v-html="evalData.reportInterpretation||'-'" />
    </div>

    <!-- 本次测评语言与感知结果评价 -->
    <div class="title">
      本次测评语言与感知结果评价
    </div>
    <div class="panel text pic-box">
      <div>
        <img
          v-if="evalData.item1EvaluationLevel"
          :src="require(`./images/level/${evalData.item1EvaluationLevel}.svg`)"
        >
      </div>
      <div v-html="evalData.item1Interpretation||'-'" />
    </div>

    <!-- 本次测评平衡与大运动结果评价 -->
    <div class="title">
      本次测评平衡与大运动结果评价
    </div>
    <div class="panel text pic-box">
      <div>
        <img
          v-if="evalData.item2EvaluationLevel"
          :src="require(`./images/level/${evalData.item2EvaluationLevel}.svg`)"
        >
      </div>
      <div v-html="evalData.item2Interpretation||'-'" />
    </div>

    <!-- 本次测评精细动作结果评价 -->
    <div class="title">
      本次测评精细动作结果评价
    </div>
    <div class="panel text pic-box">
      <div>
        <img
          v-if="evalData.item3EvaluationLevel"
          :src="require(`./images/level/${evalData.item3EvaluationLevel}.svg`)"
        >
      </div>
      <div v-html="evalData.item3Interpretation||'-'" />
    </div>

    <!-- 本次测评适应能力结果评价 -->
    <div class="title">
      本次测评适应能力结果评价
    </div>
    <div class="panel text pic-box">
      <div>
        <img
          v-if="evalData.item4EvaluationLevel"
          :src="require(`./images/level/${evalData.item4EvaluationLevel}.svg`)"
        >
      </div>
      <div v-html="evalData.item4Interpretation||'-'" />
    </div>

    <!-- 本次测评社会行为结果评价 -->
    <div class="title">
      本次测评社会行为结果评价
    </div>
    <div class="panel text pic-box">
      <div>
        <img
          v-if="evalData.item5EvaluationLevel"
          :src="require(`./images/level/${evalData.item5EvaluationLevel}.svg`)"
        >
      </div>
      <div v-html="evalData.item5Interpretation||'-'" />
    </div>

    <!-- 专家报告解读 -->
    <template v-if="evalData.evalOpinion">
      <div class="title">
        专家报告解读
      </div>
      <div class="panel text">
        {{ evalData.evalOpinion }}
      <!-- <p class="right expert">
        专家解读：XXX
      </p> -->
      </div>
    </template>

    <!-- 二维码 -->
    <div class="panel contact">
      <div
        v-for="i in 5"
        :key="i"
        class="qr"
      >
        <img src="@/assets/images/qr.png">
        <p>公众号二维码</p>
      </div>
    </div>

    <!-- 地址 -->
    <div
      class="contact"
      style="margin-top:24px"
    >
      <div class="qr">
        <img src="@/assets/images/qr.png">
        <p>公众号二维码</p>
      </div>
      <div class="address right">
        <p>{{ addr.reportHospitalName||'-' }}</p>
        <p>地址：{{ addr.reportAddress||'-' }}</p>
        <p>联系电话：{{ addr.reportTel||'-' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEvalResultData, reportPicAddressTel, queryByEvalRecordId, evalRecord, growthStandardData
} from '@/api';
import { getUserInfo } from '@/utils/auth';
import { getAge } from '@/utils/utils';
import chartData from './option';

export default {
  components: {
    Chart: () => import('@/components/Chart/Echart.vue')
  },
  filters: {
    sexConvert(value) {
      const sexMap = {
        0: '未知',
        1: '男',
        2: '女'
      };
      return sexMap[value] || '-';
    },
    strConvert(val) {
      // 报告-宝宝等级:   item1EvaluationLevel   item2EvaluationLevel  item3EvaluationLevel   item4EvaluationLevel  item5EvaluationLevel
      if (val) {
        return val;
      }
      return '-';
    }
  },
  data() {
    return {
      evalData: {},
      evalRecordData: {},
      evalRecord2Data: {},
      warnStr: '无',
      addr: {},
      // 体格数据
      chartOption: {
        data: {},
        option: {
          height: {},
          weight: {},
          bodyMassIndex: {}
        }
      },
      // 大于三岁体格数据
      chartOption2: {
        data: {},
        option: {
          height: {},
          weight: {},
          bodyMassIndex: {}
        }
      },
    };
  },
  computed: {
    evalRecordId() {
      return Number(this.$route.query.evalRecordId);
    },
    id() {
      return Number(this.$route.query.id);
    },
    orgName() {
      const user = getUserInfo() || {};
      return user.orgName || '-';
    },
    twoYearsOld() {
      if (!this.evalData.childrenBirthday) return false;
      const age = getAge(this.evalData.childrenBirthday, { getYear: true });
      return typeof age === 'number' && age >= 2;
    },
    getMonth() {
      if (!this.evalData.childrenBirthday) return false;
      const age = getAge(this.evalData.childrenBirthday, { getMonth: true });
      return age;
    },
    getAge() {
      return getAge();
    }
  },
  created() {
    this.init();
  },
  methods: {
    chartData,
    async init() {
      await this.getEvalResultData();
      this.evalRecord();
      this.evalRecord2();
      this.queryByEvalRecordId();
      this.reportPicAddressTel();
    },
    // 测评报告
    getEvalResultData() {
      const params = {
        evalRecordId: this.evalRecordId
      };
      return getEvalResultData(params).then((res) => {
        this.evalData = res.data || {};
      });
    },
    convertEvalData(list = [], configs = {}) {
      const xAxisData = [];
      const data = [];
      list.forEach((item) => {
        xAxisData.push(item[configs.dateKey || 'month']);
        data.push(item[configs.key]);
      });
      return chartData({
        xAxisName: configs.xAxisName,
        yAxisName: configs.yAxisName,
        xAxisData,
        yAxisMax: configs.yAxisMax,
        yAxisScale: configs.yAxisScale,
        series: [{
          type: 'line',
          smooth: true,
          label: {
            show: true,
            position: 'top'
          },
          data
        }],
      });
    },
    // 获取0-24月份数据
    evalRecord() {
      const timeObj = {
        startMonth: 0,
        endMonth: 24
      };
      const params = {
        id: this.id,
        evalRecordId: this.evalRecordId,
        ...timeObj
      };
      evalRecord(params).then((res) => {
        const { evalRecords, ...data } = res.data || {};
        this.chartOption.data = data || {};
        if (!data.haveEvalRecord) return;
        this.getChartOption(timeObj, (arr) => {
          this.setChart(evalRecords || [], arr, 'chartOption');
        });
      });
    },
    getChartOption(timeObj, cb) {
      // const arr = ['height','weight','bodyMassIndex']
      // 位置匹配
      const arr = ['HEIGHT_PERCENT', 'WEIGHT_PERCENT', 'BODY_MASS_INDEX'];
      const tmp = Array.from(arr, (growthClass) => this.growthStandardData({
        growthClass,
        ...timeObj
      }));
      return Promise.all(tmp).then((res) => cb(res));
    },
    setChart(evalRecords, arr, optionKey) {
      const unitMap = {
        height: 'CM',
        weight: 'Kg',
        bodyMassIndex: 'Kg/㎡',
      };
      Object.keys(this.$data[optionKey].option).forEach((key, index) => {
        this.$data[optionKey].option[key] = this.convertEvalData(evalRecords, {
          key,
          yAxisName: unitMap[key],
          xAxisName: '月',
          dateKey: 'monthAge',
          yAxisScale: true
        });
        this.$data[optionKey].option[key].series = [...this.$data[optionKey].option[key].series, ...arr[index]];
      });
    },
    // 成长标准数值
    growthStandardData(pm) {
      const params = {
        sex: this.evalData.childrenSex,
        ...pm
      };
      return growthStandardData(params).then((res) => {
        if (!(res.data || []).length) return [];
        const series = [];
        const keys = {
          threeRd: 3,
          tenTh: 10,
          twentyFiveTh: 25,
          fiftyTh: 50,
          seventyFiveTh: 75,
          ninetyTh: 90,
          ninetySevenTh: 97,
        };
        (res?.data || []).forEach((item) => {
          Object.keys(keys).forEach((key, index) => {
            if (!series[index]) {
              this.$set(series, index, {
                name: keys[key],
                type: 'line',
                smooth: true,
                symbol: 'none',
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 0.5
                    }
                  }
                },
                data: []
              });
            }
            series[index].data.push(item[key]);
          });
        });
        return series;
      }, () => []);
    },
    // 获取37月份数据
    evalRecord2() {
      const timeObj = {
        startMonth: 25,
        endMonth: 81
      };
      const params = {
        id: this.id,
        evalRecordId: this.evalRecordId,
        ...timeObj
      };
      evalRecord(params).then((res) => {
        const { evalRecords, ...data } = res.data || {};
        this.chartOption2.data = data || {};
        if (!data.haveEvalRecord) return;
        this.getChartOption(timeObj, (arr) => this.setChart(evalRecords, arr, 'chartOption2'));
      });
    },
    // 获取预警信息
    queryByEvalRecordId() {
      const params = {
        evalRecordId: this.evalRecordId
      };
      queryByEvalRecordId(params).then((res) => {
        this.warnStr = res.data?.warningBehavior || '无';
      });
    },
    // 联系方式
    reportPicAddressTel() {
      const params = {
        evalRecordId: this.evalRecordId
      };
      reportPicAddressTel(params).then((res) => {
        this.addr = res?.data || {};
      });
    }
  },
};
</script>
<style lang="less">
.el-row + .el-row{
  margin-top: 15px;
}
</style>

<style lang="less" scoped>
.report{
  min-height: 100%;
  width: 874px;
  padding: 16px;
  box-sizing: border-box;
  margin: -84px auto 0;
  .center{
    text-align: center;
  }
  .left{
    text-align: left;
  }
  .right{
    text-align: right;
  }
  .head{
    font-size: 18px;
  }
  .title{
    font-size: 16px;
    margin-top: 24px;
  }
  .note{
    font-size: 12px;
    color: #666;
  }
  .panel{
    padding: 16px;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    background: #f8f8f8;
    margin-top: 12px;
  }
  .base{
    font-size: 14px;
  }
  .chart-box{
    height: 192px;
    margin-bottom: 20px;
  }
  .long .chart-box{
    height: 250px;
    margin-bottom: 20px;
  }
  .flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text{
    line-height: 1.5;
  }
  .result{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pic-box{
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      height:78px;
      width:78px;
      margin-right: 15px;
    }
  }
  .expert{
    margin-bottom: 0;
  }
  .contact{
    @width:78px;

    display: flex;
    justify-content: space-between;
    .qr{
      width: @width;

      img{
        width:@width;
        height: @width;
        box-shadow:0 0 1px 1px #eaeaea;
      }
      p{
        text-align: center;
        margin-top: 12px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
